/*
 * All analytics functions live here, both those exported then
 * called explicitly in other parts of code and those set up
 * here via event listenters.
 */
import { StatsigClient } from "@statsig/js-client";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";
import { CryptoCurrencyValue } from "@every.org/common/src/codecs/crypto";
import { NonprofitResponse } from "@every.org/common/src/codecs/entities";
import {
  Currency,
  FREQUENCY_MULTIPLIER,
  DonationFrequency,
  PaymentMethod,
  ShareMedium,
  DonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import {
  DeviceType,
  UTM_QUERY_PARAM_TO_VAR_NAME,
  CustomEvent,
} from "@every.org/common/src/helpers/analytics";
import { ClientRouteName } from "@every.org/common/src/helpers/clientRoutes";
import {
  objectValuesToString,
  removeUndefinedValues,
} from "@every.org/common/src/helpers/objectUtilities";

import { BrowserShareData } from "src/components/ShareButton/types";
import { AuthState, AuthStatus } from "src/context/AuthContext/types";
import { getUTMValuesFromCookie, setCookie } from "src/utility/cookies";
import { getClientBotType } from "src/utility/helpers";
import { logger } from "src/utility/logger";
import { isMissingRequiredUserFields } from "src/utility/user";
import { getWindow } from "src/utility/window";

export enum ClickAction {
  IMAGE = "IMAGE",
  DONATE = "DONATE",
  DONATION = "DONATION",
  SHARE = "SHARE",
  LIKE = "LIKE",
  USER = "USER",
  CAUSE = "CAUSE",
  SUPPORTERS = "SUPPORTERS",
  NONPROFIT = "NONPROFIT",
  JOINED = "JOINED",
  PARENT_DONATION = "PARENT_DONATION",
  VIEW_MORE = "VIEW_MORE",
  RECOMMENDED_NONPROFIT = "RECOMMENDED_NONPROFIT",
  FUNDRAISER_EVENT = "FUNDRAISER_EVENT",
  TRENDING_CAUSE = "TRENDING_CAUSE",
}

const window = getWindow();

function getTimeNow() {
  return window ? performance.now() : new Date().getTime();
}

function getFacebookPixel() {
  if (!window?.fbq || getClientBotType()) {
    return undefined;
  }
  return window?.fbq;
}

function getGoogleTagManger() {
  if (!window?.dataLayer || getClientBotType()) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (event: { event: string; [additionalProperty: string]: any }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.dataLayer?.push(event as any);
  };
}

/**
 * The landing* tracking metadata tracks the ClientRouteName (eg NONPROFIT)
 * and Entity Name (eg GiveDirectly) where the user first landed.
 *
 * The entry* tracking metadata tracks what page caused them to enter a donate
 * flow, and as such it is never one of the NO_ENTRY_ROUTE_NAMES unless a user landed
 * directly on one page - we want to know what route inspired them to donate/sign up
 * not where they donated or signed up (which is tracked by the currentRouteName).
 *
 * The current* tracking metadata tracks where they currently are.
 */
let landingRouteName: string | undefined;
let landingEntityName: string | undefined;
export let entryRouteName: string | undefined;
export let entryEntityName: string | undefined;
let currentRouteName: string | undefined;
let currentEntityName: string | undefined;
let firstPageTime: number | undefined;
let currentPageTime: number | undefined;

export function setEntry(value: string, entityName?: string) {
  entryRouteName = value;
  entryEntityName = entityName;
}

function trackPageView() {
  if (!window) {
    return;
  }
  const { pathname: path, search, hash } = window.document.location;
  const properties = {
    path,
    search,
    hash,
  };
  // Using a standard track() works better for tools like MixPanel,
  trackEvent("Page View", properties);
}

function trackPageLeave() {
  if (currentPageTime !== undefined && currentRouteName !== undefined) {
    trackEvent("Page Leave", {
      durationMs: getTimeNow() - currentPageTime,
    });
  }
}

function trackSiteLeave(hiddenOrExit: string) {
  const properties = firstPageTime
    ? { durationMs: getTimeNow() - firstPageTime }
    : {};
  trackEvent("Site " + hiddenOrExit, properties);
}

/**
 * Expire the UTM values after 1 hour by default. They'll be removed earlier if
 * the user restarts their browser.
 */
const DEFAULT_UTM_EXPIRY_DAYS = 1 / 24;

function saveUtmsToCookie() {
  if (!window) {
    return;
  }
  const queryParams = new URLSearchParams(window.location.search);

  Array.from(queryParams.entries()).forEach(([key, value]) => {
    const normalizedKey = key.toLowerCase();
    const mapping = UTM_QUERY_PARAM_TO_VAR_NAME[normalizedKey];
    if (mapping) {
      setCookie(mapping, value, { expires: DEFAULT_UTM_EXPIRY_DAYS });
    }
  });
}

function saveReferringPageToCookie() {
  const referrer = window?.document.referrer;
  if (referrer) {
    const url = new URL(referrer);

    // We don't want to overwrite the referrer with internal redirects (i.e. login)
    if (!/.*every.org/.test(url.hostname)) {
      // Only log the host and pathname, makes it easier to group similar referrers
      // and we don't want to include private information from params
      const referrerString = url.hostname + url.pathname;

      setCookie(
        CookieKey.INITIAL_REFERRING_PAGE,
        JSON.stringify({
          referrer: encodeURIComponent(referrerString),
        })
      );
    }
  }
}

function saveLandingPageToCookie() {
  const referrer = window?.document.referrer;
  const landingPage = window?.document.URL;
  if (landingPage) {
    const referrerUrl = referrer ? new URL(referrer) : undefined;

    // We don't want to overwrite the landing page on internal redirects (i.e.
    // login)
    if (!referrerUrl || !/.*every.org/.test(referrerUrl.hostname)) {
      setCookie(
        CookieKey.LANDING_PAGE,
        JSON.stringify({
          landingPage: encodeURIComponent(landingPage),
        })
      );
    }
  }
}

function saveLandingInfo() {
  if (window) {
    saveUtmsToCookie();
    saveReferringPageToCookie();
    saveLandingPageToCookie();
  }
}
// Immediately save landing info - if we wait for the "load" event that will
// be after all stylesheets etc have been applied by which time user may
// have navigated somewhere else.
saveLandingInfo();

window?.addEventListener("beforeunload", () => {
  // TODO consider using navigator.sendBeacon, see https://github.com/mixpanel/mixpanel-js/issues/184
  trackPageLeave();
  trackSiteLeave("Unload");
  StatsigClient.instance()?.shutdown();
});

window?.addEventListener("visibilitychange", () => {
  if (window.document.visibilityState === "visible") {
    trackPageView();
  } else if (window.document.visibilityState === "hidden") {
    trackPageLeave();
    trackSiteLeave("Hide");
  }
});

function getTnames(ev: MouseEvent): string[] {
  if (!(ev.target instanceof HTMLElement || ev.target instanceof SVGElement)) {
    return [];
  }
  let elem: Element | null = ev.target;
  const tnames = [];
  while (elem) {
    if (elem.id === "reactRoot") {
      break; // Not strictly needed, just a minor efficiency improvement
    }
    const tnameAttr = elem.attributes["data-tname"];
    if (tnameAttr) {
      tnames.push(tnameAttr.value);
    }
    elem = elem.parentElement;
  }
  if (tnames.length === 0) {
    return ["missing"];
  }
  return tnames;
}

window?.addEventListener("click", (ev) => {
  const tnames = getTnames(ev);
  const tnamePath = tnames.join(".");
  const tname = tnames[0]; // Can be undefined
  trackEvent("Click", { tname, tnamePath });
  trackFeedButtonClick(ev);
});

/**
 * In addition to the ClientRouteName enums, we have these
 * ClientTrackingRoute for more fine-grained tracking. The
 * names of these two enumbs probably should not overlap.
 */
export enum ClientTrackingRoute {
  DONATE = "DONATE", // Anytime the page hash starts with #/donate prefer this
  NONPROFIT = "NONPROFIT",
  CAUSE = "CAUSE",
  SEARCH_DROPDOWN = "SEARCH_DROPDOWN",
  RELATED_NONPROFIT = "RELATED_NONPROFIT",
  FUND = "FUND",
  LIST = "LIST",
  COMMUNITY = "COMMUNITY",
  SELF = "SELF",
  SELF_DONATIONS = "SELF_DONATIONS",
  SELF_JOINS = "SELF_JOINS",
  SELF_LIKES = "SELF_LIKES",
  SELF_FUNDS = "SELF_FUNDS",
  SELF_FUNDRAISERS = "SELF_FUNDRAISERS",
}

/**
 * These routes should not count as entry routes
 * (unless someone landed on this page) because
 * presumably it's the page they were previously at
 * that truly inspired them to make a donation or sign
 * up, not one of these intermediary pages.
 *
 * Instead, the entry route is what caused them to enter
 * the donation/sign up flow. Did someone donate because
 * of search? Because of a cause page? User profile page?
 */
const NO_ENTRY_ROUTE_NAMES: Set<string> = new Set([
  ClientRouteName.NONPROFITS_ONBOARDING_INTRO,
  ClientRouteName.LOGIN,
  ClientRouteName.REDIRECT,
  ClientRouteName.SIGNUP,
  ClientRouteName.FUNDRAISER,
  ClientRouteName.BUILD_PROFILE,
  ClientTrackingRoute.DONATE,
  ClientTrackingRoute.NONPROFIT,
  ClientTrackingRoute.FUND,
  ClientTrackingRoute.LIST,
]);

export function trackPageChange(routeName: string, entityName?: string) {
  trackPageLeave();
  currentPageTime = getTimeNow();
  if (firstPageTime === undefined) {
    firstPageTime = currentPageTime;
    landingRouteName = routeName;
    landingEntityName = entityName;
  }
  const window = getWindow();
  currentRouteName = window?.location.hash.startsWith("#/donate")
    ? ClientTrackingRoute.DONATE
    : routeName;
  currentEntityName = entityName;
  if (
    entryRouteName === undefined ||
    !NO_ENTRY_ROUTE_NAMES.has(currentRouteName)
  ) {
    setEntry(currentRouteName, entityName);
  }
  trackPageView();
}

interface DonationProperties {
  // A unique string that we can use to differentiate between duplicated events
  uniqueId: string;
  paymentMethod: PaymentMethod;
  paymentSource?: string;
  paymentTab: string;
  paidAmount: number;
  creditAmount: number;
  tipAmount?: number;
  amount: number;
  frequency: DonationFrequency;
  toNonprofitId: NonprofitResponse["id"];
  currency: Currency;
  pledgedCryptoValue?: CryptoCurrencyValue;
  usdValueAtPledge?: string;
  hasPrivateNote: boolean;
  hasTestimonial?: boolean;
  shareInfo?: boolean;
  isPublic?: boolean;
}

export enum LoginMethod {
  SOCIAL = "SOCIAL",
  EMAIL = "EMAIL",
}
/**
 * We track login attempts instead of successful logins because login requires a
 * full-page redirect, potentially resetting the user's session, etc. so it's
 * hard to track "login success" on the same a/b testing ID. This is probably a
 * pretty good proxy for a user logging in.
 */
export function trackLoginAttempt(
  method: LoginMethod,
  provider?: IdentityProvider
) {
  try {
    trackEvent(CustomEvent.USER_LOGIN_ATTEMPT, {
      loginMethod: method,
      identityProvider: provider,
    });
  } catch (error) {
    logger.warn({ message: "Error while recording login.", error });
  }
}

export function trackSignUp() {
  // Value sign ups as much as a $2 one-time donation - most people who
  // do additional conversion (donate, nonprofit admin, fundraise) will do
  // soon after signing up, at which point they will have higher conversion value.
  const conversionValue = 2;
  const metadata = { conversionValue };
  try {
    trackEvent(CustomEvent.USER_SIGN_UP, metadata);
    const pixel = getFacebookPixel();
    if (pixel) {
      // Facebook conversion tracking
      pixel("track", "CompleteRegistration");
    }
  } catch (error) {
    logger.warn({ message: "Error while recording sign up.", error });
  }
}

function getStripePaymentOption(stripeSourceId: string) {
  if (stripeSourceId.startsWith("ba_")) {
    return DonationFlowPaymentOption.BANK;
  }
  if (stripeSourceId.startsWith("pm_")) {
    return DonationFlowPaymentOption.PAYMENT_REQUEST;
  }
  return DonationFlowPaymentOption.CREDIT_CARD;
}

function getDonationFlow(
  paymentMethod: PaymentMethod,
  stripeSourceId?: string
) {
  if (paymentMethod === PaymentMethod.STRIPE && stripeSourceId) {
    return getStripePaymentOption(stripeSourceId);
  }
  return paymentMethod;
}

export function trackDonate(properties: DonationProperties) {
  const conversionValue =
    properties.paidAmount * FREQUENCY_MULTIPLIER[properties.frequency];
  const donationFlow = getDonationFlow(
    properties.paymentMethod,
    properties.paymentSource
  );
  const metadata = {
    conversionValue,
    amount: properties.amount,
    creditAmount: properties.creditAmount,
    revenue: properties.paidAmount,
    tipAmount: properties.tipAmount,
    donationFrequency: properties.frequency,
    currencyCode: properties.currency,
    donationFlow,
    currency: properties.currency,
    conversionId: properties.uniqueId,
    toNonprofitId: properties.toNonprofitId,
    // TODO(#16662) Remove donationEntryPage and donationEntryPageEntityName
    donationEntryPage: entryRouteName,
    donationEntryPageEntityName: entryEntityName,
    paymentTab: properties.paymentTab,
    cryptoCurrency: properties?.pledgedCryptoValue?.currency,
    cryptoAmount: properties?.pledgedCryptoValue?.amount.toString(),
    usdValueAtPledge: properties?.usdValueAtPledge?.toString(),
    hasPrivateNote: properties.hasPrivateNote,
    hasTestimonial: properties.hasTestimonial,
    shareInfo: properties.shareInfo,
    isPublic: properties.isPublic,
  };

  try {
    trackEvent(CustomEvent.DONATE, metadata);
    const pixel = getFacebookPixel();
    if (pixel) {
      // Facebook conversion tracking
      pixel("track", "Purchase", {
        currency: properties.currency,
        value: conversionValue,
      });
    }
  } catch (error) {
    logger.warn({ message: "Error while recording donation.", error });
  }
}

interface ShareClickProperties {
  medium: ShareMedium;
  shareData: BrowserShareData | ((medium: ShareMedium) => BrowserShareData);
}
export function trackShareButtonClick(properties: ShareClickProperties) {
  try {
    const shareData = properties.shareData;
    const medium = properties.medium;

    const browserShareData =
      typeof shareData === "function" ? shareData(medium) : shareData;

    const metadata = {
      medium,
      shareLink: browserShareData.url,
    };
    trackEvent(CustomEvent.SHARE_BUTTON_CLICK, metadata);
  } catch (error) {
    logger.warn({ message: "Error while recording share button click", error });
  }
}

const FEED_EVENT_ATTRIBUTES = [
  // Feed list level
  { attrName: "data-feed-page", propertyName: "feedpage" },
  { attrName: "data-feed-user", propertyName: "userid" },
  // Card level
  { attrName: "data-feed-feedid", propertyName: "feedid" },
  { attrName: "data-feed-itemid", propertyName: "itemid" },
  { attrName: "data-feed-itemtype", propertyName: "itemtype" },
  // Button level
  { attrName: "data-action", propertyName: "action" },
];
function trackFeedButtonClick(ev: MouseEvent) {
  const properties = {};
  let elem: Element | null = ev.target as Element;
  while (elem) {
    if (elem.id === "reactRoot") {
      break; // Not strictly needed, just a minor efficiency improvement
    }
    for (const { attrName, propertyName } of FEED_EVENT_ATTRIBUTES) {
      const value =
        typeof elem.getAttribute === "function"
          ? elem.getAttribute(attrName)
          : undefined;
      if (value) {
        properties[propertyName] = value;
      }
    }
    elem = elem.parentElement;
  }
  if (Object.keys(properties).length === FEED_EVENT_ATTRIBUTES.length) {
    trackEvent(CustomEvent.FEED_BUTTON_CLICK, properties);
    logger.info({ data: properties, message: CustomEvent.FEED_BUTTON_CLICK });
  }
}

export function trackEvent<Data>(event: string, properties: Data) {
  const isLoggedIn = authState?.status === AuthStatus.LOGGED_IN;
  const userAgent = window?.navigator.userAgent;

  const conversionValue = properties["conversionValue"];
  const trackedProperties = removeUndefinedValues({
    landingRouteName,
    landingEntityName,
    entryRouteName,
    entryEntityName,
    currentRouteName,
    currentEntityName,
    // TODO(#16662) Remove currentPageName
    currentPageName: currentRouteName,
    isLoggedIn,
    userAgent,
    botType: getClientBotType(),
    currentUserType: getAnalyticsUserType(),
    ...getUTMValuesFromCookie(),
    ...properties,
  });

  const hotjar = getWindow()?.hj;
  hotjar && hotjar("event", event);

  // This is showing a warning:
  // "StatsigClient.instance is not supported in server environments"
  // Not sure how to differentiate when this is running on the server vs client
  // Overall it doesn't seem like a big issue - the Statsig sdk just returns a
  // new instance of the client
  // https://github.com/statsig-io/js-client-monorepo/blob/d9e7a7b0969161033aa5822fd4c36e937626b8a1/packages/js-client/src/StatsigClient.ts#L66
  StatsigClient.instance().logEvent(
    event,
    typeof conversionValue === "number" ? conversionValue : undefined,
    objectValuesToString(trackedProperties)
  );

  const gtm = getGoogleTagManger();
  if (gtm) {
    gtm({
      event,
      ...trackedProperties,
    });
  }
}

/**
 * @param testName The AB Test name
 * @param variant The variant/bucket that was chosen for the user
 */
export function trackABTest(testName: string, variant: string) {
  const properties = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "Experiment name": testName,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "Variant name": variant,
  };
  trackEvent(CustomEvent.EXPERIMENT_TRIGGERED, properties);

  const hotjar = getWindow()?.hj;
  hotjar && hotjar("event", `ABTEST_${testName}_${variant}`);
}

let authState: AuthState | undefined;
export function setAnalyticsAuthStatus(newState: AuthState) {
  authState = newState;
}

enum AnalyticsUserType {
  LOGGED_OUT = "LOGGED_OUT",
  GUEST = "GUEST",
  IN_ONBOARDING = "IN_ONBOARDING",
  UNVERIFIED_USER = "UNVERIFIED",
  VERIFIED_USER = "VERIFIED",
}
function getAnalyticsUserType() {
  if (authState?.status === AuthStatus.LOADING) {
    return; // Don't track this property if we haven't fetched auth state yet
  }

  // Guest user is a special case, technically not logged out or logged in
  if (authState?.guestUser) {
    return AnalyticsUserType.GUEST;
  }

  if (authState?.status !== AuthStatus.LOGGED_IN) {
    return AnalyticsUserType.LOGGED_OUT;
  }

  if (isMissingRequiredUserFields(authState.user)) {
    return AnalyticsUserType.IN_ONBOARDING;
  }

  return authState.user.isEmailVerified
    ? AnalyticsUserType.VERIFIED_USER
    : AnalyticsUserType.UNVERIFIED_USER;
}

export function getDeviceType() {
  const ua = getWindow()?.navigator.userAgent || "";
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
  const isiPhone = /iPhone|iPod/i.test(ua);
  const isAndroid = /Android/i.test(ua);
  const isTablet = /iPad|tablet/i.test(ua);

  return isiPhone
    ? DeviceType.iphone
    : isAndroid
    ? DeviceType.android
    : isTablet
    ? DeviceType.tablet
    : !isMobile
    ? DeviceType.desktop
    : DeviceType.unknown;
}
