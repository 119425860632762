import { createClient } from "@vercel/edge-config";
import { NextPageContext } from "next/types";
import Statsig from "statsig-node";
import { EdgeConfigDataAdapter } from "statsig-node-vercel";

import { StatsigTier } from "@every.org/common/src/entity/types";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import { TEST_ID_CACHE_EXPIRY_DAYS } from "@every.org/common/src/helpers/abtesting/index";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import {
  createTestingId,
  getTestingIdFromServerCookie,
} from "src/utility/abtesting";
import { setCookie } from "src/utility/cookies";

// This points to a cloudflare worker that proxies requests to statsig
// TODO - probably simpler and maybe more performant to use vercel / nextjs to
// proxy these rather than a cloudflare worker
// https://docs.statsig.com/client/javascript-sdk/next-js#advanced---network-proxy
export const STATSIG_PROXY_URL = "https://p.every.org/v1";

const tier: StatsigTier = ["production", "staging", "development"].includes(
  process.env.NEXT_PUBLIC_ENVIRONMENT_NAME || ""
)
  ? (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME as StatsigTier)
  : undefined;

async function initializeStatsig() {
  await Statsig.initialize(
    assertEnvPresent(
      process.env.STATSIG_SECRET_API_KEY,
      "STATSIG_SECRET_API_KEY"
    ),
    {
      environment: {
        tier,
      },
      initTimeoutMs: 5000,
      dataAdapter: getEdgeConfigDataAdapter(),
    }
  );
}

const getEdgeConfigDataAdapter = () => {
  if (process.env.EDGE_CONFIG && process.env.STATSIG_EDGE_CONFIG_ITEM_KEY) {
    const edgeConfigClient = createClient(process.env.EDGE_CONFIG);
    const dataAdapter = new EdgeConfigDataAdapter({
      edgeConfigClient: edgeConfigClient,
      edgeConfigItemKey: process.env.STATSIG_EDGE_CONFIG_ITEM_KEY,
    });

    return dataAdapter;
  }

  return undefined;
};

export const getServerSideTestingProps = async (ctx: NextPageContext) => {
  let statsigInitProps, testingId;
  // This is all server-side only
  if (ctx.req && process.env.STATSIG_SECRET_API_KEY) {
    // TODO#15453: on a redirect the server cookie might not be there.
    // Eg: being redirected from auth0 after signup
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    testingId = getTestingIdFromServerCookie(ctx.req.headers.cookie);

    if (!testingId) {
      testingId = createTestingId();
      setCookie(CookieKey.TESTING_ID_CACHE, testingId, {
        expires: TEST_ID_CACHE_EXPIRY_DAYS,
      });
    }

    await initializeStatsig();

    statsigInitProps = Statsig.getClientInitializeResponse(
      {
        userID: testingId,
        customIDs: {
          stableID: testingId,
        },
      },
      assertEnvPresent(
        process.env.NEXT_PUBLIC_STATSIG_PUBLIC_API_KEY,
        "STATSIG_API_KEY"
      ),
      // The default hashing algo for the client was changed (but not the server)
      // https://docs.statsig.com/client/javascript-sdk/migrating-from-statsig-react#bootstrapping-the-statsigclient
      { hash: "djb2" }
    );
  }

  return { statsigInitProps, abTestingId: testingId };
};
